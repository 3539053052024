import {Component, HostListener, OnInit} from '@angular/core';
import {Slide} from "../../models/slide";
import {Router} from "@angular/router";
import {Carousel, initTE,} from "tw-elements";
import {goToUrl} from "../../services/util/util";

@Component({
    selector: 'app-hero-section',
    templateUrl: './hero-section.component.html',
    styleUrls: ['./hero-section.component.css'],
})
export class HeroSectionComponent implements OnInit {

    isMobile: boolean = false;

    slides: Slide[] = [
        {
            bgUrl: "https://scontent.fskp3-1.fna.fbcdn.net/v/t39.30808-6/358730250_810552090763172_7025745770982511660_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=Yb7KD5cKsvEQ7kNvgEbHH6Z&_nc_zt=23&_nc_ht=scontent.fskp3-1.fna&_nc_gid=AOPGZGQYcLljI_EIjeOWlFy&oh=00_AYAKaBDQBWWh4uB48-j8Jecmfn3j_fb6c9cTs51TeG0hLA&oe=673E4249",
            buttonText: "КУПИ АЛОЕ ВЕРА ГЕЛ",
            ariaLabel: "Алое вера гел",
            action: () => goToUrl(this.router, '/shop/aloe-vera-pijaloci')
        },
        {
            bgUrl: "https://scontent.fskp3-1.fna.fbcdn.net/v/t39.30808-6/463463530_2913106688854362_5047548783395373590_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=cc71e4&_nc_ohc=1eC7ZkZBCAMQ7kNvgGn8uPt&_nc_zt=23&_nc_ht=scontent.fskp3-1.fna&_nc_gid=Aq7tzdveufJvwNnp_n4-p35&oh=00_AYAPkD5CNTG6pfWI8UGs2UBqPqSZrqFDvldoXOvLLIr9kQ&oe=673E3EEF",
            buttonText: "БИЗНИС МОЖНОСТ",
            ariaLabel: "Производи од алое вера",
            action: () => goToUrl(this.router, '/biznis-moznost')
        },
        // {
        //     bgUrl: "https://cdn.foreverliving.com/content/products/images/FOREVER_BEE_PROPOLIS_pd_category_256_X_256_1669037162396.jpg",
        //     buttonText: "ПЧЕЛНИ ПРОИЗВОДИ",
        //     ariaLabel: "Пчелни производи",
        //     action: () => goToUrl(this.router, '/shop/pcelni-proizvodi')
        // },
        // {
        //     bgUrl: "https://cdn.foreverliving.com/content/products/images/dx4_body_balancing_system_pd_category_256_X_256_1669808080165.jpg",
        //     buttonText: "ДОДАТОЦИ ВО ИСХРАНА",
        //     ariaLabel: "Додатоци во исхрана",
        //     action: () => goToUrl(this.router, '/shop/dodatoci-vo-ishrana')
        // },
        // {
        //     bgUrl: "https://lwf.mk/public/wp-content/uploads/2023/02/aloe-body-lotion.jpg",
        //     buttonText: "ЛИЧНА НЕГА",
        //     ariaLabel: "Производи за лична нега",
        //     action: () => goToUrl(this.router, '/shop/licna-nega')
        // },
        // {
        //     bgUrl: "https://aloevera.mk/wp-content/uploads/2023/12/R3-Factor-Skin-Defence-Creme-s-1024x1024.jpg",
        //     buttonText: "НЕГА ЗА ЛИЦЕ",
        //     ariaLabel: "Производи за нега на лице",
        //     action: () => goToUrl(this.router, '/shop/nega-za-lice')
        // },
        // {
        //     bgUrl: "https://cdn.foreverliving.com/content/products/images/ALOE_PROPOLIS_CREME_pd_category_256_X_256_1669126070066.jpg",
        //     buttonText: "НЕГА ЗА ТЕЛО",
        //     ariaLabel: "Производи за нега на тело",
        //     action: () => goToUrl(this.router, '/shop/nega-za-telo')
        // },
        // {
        //     bgUrl: "https://aloevera.mk/wp-content/uploads/2023/12/471_Forever_Lite_Ultra_Chocolate_l.png",
        //     buttonText: "КОНТРОЛА НА ТЕЛЕСНА ТЕЖИНА",
        //     ariaLabel: "Производи за контрола на телесна тежина",
        //     action: () => goToUrl(this.router, '/shop/kontrola-na-telesna-tezina')
        // },
    ];

    constructor(
        private readonly router: Router
    ) {
    }

    ngOnInit() {
        initTE({Carousel});
        this.checkIfMobile();
    }

    @HostListener('window:resize')
    onResize() {
        this.checkIfMobile();
    }

    checkIfMobile() {
        const screenWidth = window.innerWidth;
        this.isMobile = screenWidth < 1280;
    }
}