import {Component} from '@angular/core';
import {Link} from "../../models/link";
import {environment} from "../../environments/environment";
import {IconLink} from "../../models/iconLink";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent {

    iconLinks: IconLink[] = [
        {
            icon: '/assets/icons/social-media/instagram_logo.svg',
            link: 'https://www.instagram.com/aloeveragel.mk/'
        },
        {
            icon: '/assets/icons/social-media/facebook_logo.svg',
            link: 'https://www.facebook.com/profile.php?id=61568994295824'
        },
        {
            icon: '/assets/icons/social-media/tiktok_logo.svg',
            link: 'https://www.tiktok.com/@aloeveragel.mk'
        }
    ]

    links: Link[] = [
        {
            title: "ПОЧЕТНА",
            text: "Почетна",
            route: environment.navigation.home,
        },
        {
            title: "ПРОДАВНИЦА",
            text: "Продавница",
            route: environment.navigation.shop,
        },
        {
            title: "БЛОГ",
            text: "Блог",
            route: environment.navigation.blog,
        },
        {
            title: "БИЗНИС МОЖНОСТ",
            text: "Бизнис можност",
            route: environment.navigation.brandAmbassador,
            class: "text-secondary"
        },
        {
            title: "ЗА НАС",
            text: "За нас",
            route: environment.navigation.aboutUs,
        },
        {
            title: "КОНТАКТ",
            text: "Контакт",
            route: environment.navigation.contactUs,
        },
    ];

    shopLinks: Link[] = [
        {text: "Алое вера пијалоци", route: "shop/aloe-vera-pijaloci"},
        {text: "Пчелни производи", route: "shop/pcelni-proizvodi"},
        {text: "Додатоци во исхрана", route: "shop/dodatoci-vo-ishrana"},
        {text: "Лична нега", route: "shop/licna-nega"},
        {text: "Нега за лице", route: "shop/nega-za-lice"},
        {text: "Етерични масла", route: "shop/etericni-masla"},
        {text: "Нега за тело", route: "shop/nega-za-telo",},
        {text: "Контрола на телесната тежина", route: "shop/kontrola-na-telesna-tezina",},
    ];

    openExternalLink(url: string): void {
        window.open(url, '_blank');
    }
}