import {Component, Inject, OnInit} from '@angular/core';
import {ShopService} from "../../service/shop.service";
import {Product} from "../../../../models/product";
import {Observable, switchMap, take} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {DropdownOption} from "../../../../components/dropdown/model/dropdown-option";
import {CartService} from "../../../cart/service/cart.service";
import {Carousel, initTE, Sidenav, Toast} from "tw-elements";
import {OrderItem} from "../../../../models/order-item";
import {Link} from "../../../../models/link";
import {environment} from "../../../../environments/environment.production";
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'app-product-page',
    templateUrl: './product-page.component.html',
    styleUrl: './product-page.component.css'
})
export class ProductPageComponent implements OnInit {

    product!: Observable<Product>;

    orderItemControl: UntypedFormGroup = new UntypedFormGroup({
        quantity: new UntypedFormControl('', [Validators.required]),
        size: new UntypedFormControl('', [Validators.required]),
        color: new UntypedFormControl('', [Validators.required,])
    });

    toastMessage: string = '';

    sizeOptions: DropdownOption[] = [
        {
            label: 'S',
            value: 'S'
        },
        {
            label: 'M',
            value: 'M'
        },
        {
            label: 'L',
            value: 'L'
        },
        {
            label: 'XL',
            value: 'XL'
        },
        {
            label: 'XXL',
            value: 'XXL'
        },
        {
            label: 'XXXL',
            value: 'XXXL'
        }
    ]

    jerseySizeOptions: DropdownOption[] = [
        {
            label: '116-122 (3-4 год)',
            value: '116-122 (3-4 год)'
        },
        {
            label: '128-134 (5-6 год)',
            value: '128-134 (5-6 год)'
        },
        {
            label: '140-152 (7-8 год)',
            value: '140-152 (7-8 год)'
        },
        {
            label: '152 (9-10 год)',
            value: '152 (9-10 год)'
        },
        {
            label: '164 (10-11 год)',
            value: '164 (10-11 год)'
        },
        {
            label: '170 (11-12 год)',
            value: '170 (11-12 год)'
        },
        {
            label: 'S',
            value: 'S'
        },
        {
            label: 'M',
            value: 'M'
        },
        {
            label: 'L',
            value: 'L'
        },
        {
            label: 'XL',
            value: 'XL'
        },
        {
            label: 'XXL',
            value: 'XXL'
        },
    ]

    colorOptions: DropdownOption[] = [
        {
            label: 'Црна',
            value: 'crna'
        },
        {
            label: 'Бела',
            value: 'bela'
        },
    ]

    links: Link[] = [
        {
            text: "Почетна",
            route: environment.navigation.home,
        },
        {
            text: "Продавница",
            route: environment.navigation.shop,
        }]

    constructor(private route: ActivatedRoute,
                private readonly router: Router,
                private shopService: ShopService,
                private cartService: CartService,
                private meta: Meta,
                private titleService: Title,
                @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit(): void {
        this.product = this.route.paramMap.pipe(
            switchMap(params => {
                const slugAndId = params.get('slugAndId')!;

                let id: number;

                if (slugAndId.includes('-')) {
                    id = +slugAndId.split('-').pop()!;
                } else {
                    id = +slugAndId;
                }
                return this.shopService.getProductById(id);
            })
        );

        this.product.subscribe(product => {
            this.updateLinks(product.category);
            this.setCanonicalUrl(product);
            this.setTitle(product);
            this.setMetaDescription(product);
            this.setStructuredData(product);
            this.setMetaKeywords(product);
            setTimeout(() => initTE({Carousel}), 500);
        });
    }

    setStructuredData(product: Product) {
        const script = this.document.createElement('script');
        const productImage = environment.domain.frontend + "/assets/images/" + product?.category?.toLowerCase()?.replace('_', '-') + '/' + (product?.images?.[0] ?? '') + '.webp';

        script.type = 'application/ld+json';

        const jsonLd = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": this.setProductCategory(product.category) + product.name,
            "image": productImage,
            "description": product.description ?? '',
            "sku": product.designID ?? '',
            "brand": {
                "@type": "Brand",
                "name": "АлоеВераГел.мк"
            },
            "offers": {
                "@type": "Offer",
                "priceCurrency": "MKD",
                "price": product.price ?? '',
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "url": window.location.href
            }
        };
        script.text = JSON.stringify(jsonLd);
        this.document.head.appendChild(script);
    }

    get quantityControl(): UntypedFormControl {
        return this.orderItemControl.get('quantity') as UntypedFormControl;
    }

    get sizeControl(): UntypedFormControl {
        return this.orderItemControl.get('size') as UntypedFormControl;
    }

    get colorControl(): UntypedFormControl {
        return this.orderItemControl.get('color') as UntypedFormControl;
    }

    onSizeChange(selectedValue: string): void {
        this.sizeControl.setValue(selectedValue);
    }

    onColorChange(selectedValue: string): void {
        this.colorControl.setValue(selectedValue);
    }

    addToCart() {
        this.isOrderItemValid().then((isValid) => {
            if (isValid) {
                this.createOrderItem().then(() => {
                    const cartOverview = new Sidenav(document.getElementById("cart-overview"));
                    cartOverview.show();
                }).catch(error => {
                    this.openToast('Failed to add item to cart');
                    console.error(error);
                });
            } else {
                this.openToast('Пополни ги полињата за нарачка');
            }
        });
    }

    buyNow() {
        this.isOrderItemValid().then((isValid) => {
            if (isValid) {
                this.createOrderItem().then(() => {
                    this.router.navigateByUrl('/checkout');
                }).catch(error => {
                    this.openToast('Failed to proceed to checkout');
                    console.error(error);
                });
            } else {
                this.openToast('Пополни ги полињата за нарачка');
            }
        });
    }

    private isOrderItemValid(): Promise<boolean> {
        return new Promise((resolve) => {
            this.product.pipe(take(1)).subscribe((product) => {
                const isValid = this.orderItemControl.status === "VALID" ||
                    product?.category === "FLAG" ||
                    product?.category === "BOTTLE" ||
                    product?.category === "OTHER" ||
                    product?.category === "JERSEY";
                resolve(isValid);
            });
        });
    }

    private createOrderItem(): Promise<OrderItem> {
        return new Promise((resolve, reject) => {
            this.product.pipe(take(1)).subscribe({
                next: (product) => {
                    const orderItem: OrderItem = {
                        product: product,
                        quantity: this.quantityControl.value,
                        size: this.sizeControl.value,
                        color: this.colorControl.value,
                    };

                    this.cartService.updateCartItems([...this.cartService.retrieveItemsFromLocalStorage(), orderItem]);
                    this.updateLocalStorage(orderItem);
                    resolve(orderItem);
                },
                error: (error) => reject(error)
            });
        });
    }

    private updateLocalStorage(orderItem: OrderItem): void {
        const existingItems = this.getExistingItems();
        existingItems.push(orderItem);
        localStorage.setItem('cartItems', JSON.stringify(existingItems));
    }

    private getExistingItems(): OrderItem[] {
        const existingItemsJson = localStorage.getItem('cartItems');
        return existingItemsJson ? JSON.parse(existingItemsJson) : [];
    }

    setProductCategory(category: string | undefined) {
        switch (category) {
            case 'T_SHIRT':
                return "Маица - "
            case 'HOODIE':
                return "Дуксер - "
            case 'SWEAT_SHIRT':
                return "Блузон - "
            case 'JERSEY':
                return "Дрес - "
            case 'FLAG':
                return "Знаме - "
            default :
                return ""
        }
    }

    setLinkTitle(category: string | undefined) {
        switch (category) {
            case 'T_SHIRT':
                return "Маици"
            case 'HOODIE':
                return "Дуксери"
            case 'SWEAT_SHIRT':
                return "Блузони"
            case 'JERSEY':
                return "Дресови"
            case 'FLAG':
                return "Знамиња"
            case 'BOTTLE':
                return "Шишиња"
            default :
                return "Останато"
        }
    }

    setCanonicalUrl(product: Product) {
        const canonicalUrl = environment.domain.frontend + environment.navigation.shop + '/product/' + (product?.slug ? product?.slug + '-' + product?.id : product?.id);
        this.meta.updateTag({name: 'canonical', content: canonicalUrl});
    }

    setTitle(product: Product) {
        this.titleService.setTitle(this.setProductCategory(product.category) + product.name + " | Непокор.мк");
    }

    updateLinks(category: string | undefined) {
        if (category) {
            const newLink: Link = {
                text: this.setLinkTitle(category),
                route: `${environment.navigation.shop}/${category === "OTHER" ? category.toLowerCase() : category.toLowerCase().concat("s")}`
            };

            const linkExists = this.links.some(link => link.route === newLink.route);

            if (!linkExists) {
                this.links.push(newLink);
            }
        }
    }

    setMetaDescription(product: Product) {
        let description = `${product.name} - ${product.description}`;

        if (description.length > 160) {
            description = description.substring(0, 157) + '...';
        }

        this.meta.updateTag({name: 'description', content: description});
    }

    private setMetaKeywords(product: Product): void {
        const keywords = product.keywords?.join(', ') ?? '';

        this.meta.updateTag({name: 'keywords', content: keywords});
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }

}