<div class="container mt-16 lg:mt-24 mx-auto md:px-6 h-full">
    <div class="relative h-[200px] lg:h-[300px] overflow-hidden bg-cover bg-center bg-no-repeat bg-[url('/')]">
    </div>
    <div class="container px-6 md:px-12 pb-10 md:pb-20">
        <div class="block rounded-lg bg-[hsla(0,0%,100%,0.7)] px-6 py-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:px-12 -mt-[100px] backdrop-blur-[30px]">
            <app-text [class]="'text-lg lg:text-4xl font-medium lg:font-bold text-center py-2'"
                      [value]="'КОНТАКТИРАЈТЕ НÈ'"></app-text>
            <form class="space-y-3 mx-auto max-w-[700px]" [formGroup]="contactForm">
                <div>
                    <app-text-input [floatLabel]="true"
                                    [label]="'Име'"
                                    [control]="nameControl"
                                    [type]="'required'"></app-text-input>
                </div>
                <div>
                    <app-text-input [floatLabel]="true"
                                    [label]="'Е-маил адреса'"
                                    [control]="emailControl"
                                    [type]="'email'"></app-text-input>
                </div>
                <div>
                    <app-text-input [floatLabel]="true"
                                    [label]="'Наслов на пораката'"
                                    [control]="subjectControl"
                                    [type]="'required'"></app-text-input>
                </div>
                <div class="relative z-0" data-te-input-wrapper-init>
                                <textarea [formControl]="messageControl"
                                          id="floating_standard"
                                          rows="3"
                                          class="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"></textarea>
                    <label for="floating_standard"
                           class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
                        Порака</label>
                </div>
                <div class="pt-3">
                    <app-primary-button [isLoading]="isLoading" class="pt-4" [value]="'Испрати'"
                                        (click)="sendMessage()"></app-primary-button>
                </div>
            </form>
            <app-toast [message]="toastMessage"></app-toast>
        </div>
    </div>
</div>
<app-footer></app-footer>